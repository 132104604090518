export const VERTICAL_MAP = {
  'Bouillon & Seasoning': ['Broth/Bouillon', 'Seasonings'],
  'Mini meals': ['Mini meals', 'Flavored Rice/Pasta Sides', 'Mac & Cheese'],
  Condiments: [
    'Mayonnaise',
    'Variety Sauces',
    'Mustard',
    'Ketchup',
    'Barbecue Sauce',
    'Flavored Mayo',
    'Better-for-You Mayo',
  ],
  'Lifestyle Nutrition': ['Granola & Nutrition Bars', 'Nutritional powders'],
  Others: ['Ready to Heat meals'],
};

export const VERTICAL_OPTIONS = Object.keys(VERTICAL_MAP).map((item) => ({
  label: item,
  value: item,
}));

export function getVerticalOptionValue(val = '') {
  return VERTICAL_MAP[val] ?? [];
}
