import React, { useCallback, useEffect, useState, useRef } from 'react';

import style from './index.module.scss';

import ListMain from '../../components/list-main/listMain.jsx';
import PlantHeader from '@/modules/top-dish/components/header/header.jsx';
import title from '../../img/title.png';
import { ConfigProvider, Dropdown, Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { topDishGetList } from '@/modules/top-dish/api/request';
import EventBus from '@/modules/top-dish/hooks/eventBus';
import { itemsRight, smoothScrollTo } from '@/modules/top-dish/components/list-main/SpreadSheet';

const HomePage = () => {

  const pageView = useRef(null);

  const [showList, setShowList] = useState(false);
  const [showKey, setShowKey] = useState('United States');


  useEffect(() => {
    //挂载
    EventBus.addEventListener('topDishTitleKeyEvent', topDishTitleKeyEvent);
    EventBus.addEventListener('topDishTitleKeySetTopEvent', topDishTitleKeySetTopEvent);
    return (() => {
      //卸载
      EventBus.removeEventListener('topDishTitleKeyEvent', topDishTitleKeyEvent);
      EventBus.removeEventListener('topDishTitleKeySetTopEvent', topDishTitleKeySetTopEvent);
    });
  }, [showKey]);

  const topDishTitleKeySetTopEvent = (e) => {
    if (pageView.current) {
      smoothScrollTo(0, pageView.current.offsetTop, 200);
    }
    console.log('showKey接收到传值啦！~~~~~~', e.detail);
  };

  const topDishTitleKeyEvent = (e) => {
    setShowKey(e.detail.key);
    if (pageView.current) {
      smoothScrollTo(0, pageView.current.offsetTop, 1200);
    }
    console.log('showKey接收到传值啦！~~~~~~', e.detail);
  };

  const onClickMenu = async (e) => {
    console.log('click ', e);
    setShowKey(e.key);
    const result = await topDishGetList({ key: e.key });
    console.log(result);


    setShowList(true);
    setTimeout(() => {
      EventBus.dispatchEvent('topDishTitleKeyEvent', { key: e.key, data: result.data });
      EventBus.dispatchEvent('topDishListEvent', { country: e.key, data: result.data });
    }, 100);

    // setTimeout(() => {
    //   if (pageView.current) {
    //     smoothScrollTo(0, pageView.current.offsetTop, 1300);
    //   }
    // }, 200);

  };

  return <>
    <div className={style.boxWrap}>
      <div className={style.main}>
        <img className={style.title} src={title} alt="" />

        <div className={style.selectBox}>
          <ConfigProvider
            theme={{
              components: {
                Dropdown: {
                  paddingBlock: 12,
                },
              },
            }}
          >
            <Dropdown menu={{ items: itemsRight, onClick: onClickMenu }} overlayStyle={{ width: '220px' }}
              className={style.headerRightMenu} placement="top" trigger="click">
              <span onClick={(e) => e.preventDefault()} style={{display:'flex',cursor:'pointer'}}>
                <a href="" className={style.seleceText} style={{ color: '#00B704' }}>{showKey}
                  <RightOutlined className={style.seleceTextJt} />
                </a>
              </span>
            </Dropdown>
          </ConfigProvider>
        </div>
      </div>

      {showList &&
        <div ref={pageView} style={{ position: 'absolute', top: '100vh', width: '100%', height: '100vh' }}>
          {/*<>*/}
          <PlantHeader></PlantHeader>
          <ListMain />
          {/*</>*/}
        </div>
      }

    </div>
  </>;
};

export default HomePage;