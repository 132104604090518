/* eslint-disable indent */
import { ConfigProvider, Select, theme } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFilter, getImageById, getProduct } from '../../api/index.js';
import BorderBox from '../../components/box/index.jsx';
import TopBar from '../../components/top-bar/top.jsx';
import VideoBg from '../../components/video-bg/bg.jsx';
import style from './brand.module.scss';

function BrandPage() {
  const [brandValue, setBrandValue] = useState(null);
  const [OptionsBrand, setOptionsBrand] = useState([]);
  const [searchParams] = useSearchParams();
  const [brandData, setBrandData] = useState({
    id: 0,
    title: '',
    metrics: [
      { label: 'Brand Visibility', value: 0 },
      { label: 'Appeal', value: 0 },
      { label: 'Unpriced Purchase Intent', value: 0 },
    ],
    hasMore: false,
    children: [],
  });

  // 图片缓存
  const [imgCache, setImgCache] = useState(new Map());

  useEffect(() => {
    const fetchDataAndBuild = async () => {
      await getOptionsData();
    };
    const brand = searchParams.get('brand');
    if (brand) {
      setBrandValue(brand);
      fetchDataAndBuild();
    }
  }, [searchParams]);

  const getOptionsData = async () => {
    const { data: options } = await getFilter('brand', {
      market: [],
      company: [],
      category: [],
      brand: [],
    });
    if (options) {
      setOptionsBrand(options.map((e) => ({ label: e, value: e })));
    }
  };

  const fetchData = async () => {
    if (!brandValue || brandValue === null) {
      return;
    }
    setShowBranch2(false);
    const { data: resultArr } = await getProduct(
      {
        market: [],
        company: [],
        category: [],
        brand: [brandValue],
      },
      {
        generation: [],
        gender: [],
        user_vs_nouser: [],
        social_class: [],
      },
      '',
      'desc',
      'brand_logo_visibility'
    );
    // console.log('resultArr', resultArr);
    const newList = resultArr;
    for (const item of newList) {
      if (!imgCache.has(item.sn)) {
        const url = await getImageById(item.sn, item.market);
        setImgCache(imgCache.set(item.sn, url));
      }
    }
    newList.forEach((item) => {
      item.img = imgCache.get(item.sn);
      item.isLoadingImg = false;
    });

    await buildData(newList);
  };

  const markFlags = (score) => {
    let flag;
    if (Number(score) > 70) {
      flag = 'ok';
    }
    if (Number(score) >= 41 && Number(score) <= 70) {
      flag = 'bad';
    }
    if (Number(score) < 41) {
      flag = 'worse';
    }
    return flag;
  };

  const buildData = async (data) => {
    setBrandData({
      id: 0,
      title: '',
      metrics: [
        { label: 'Brand Visibility', value: 0 },
        { label: 'Appeal', value: 0 },
        { label: 'Unpriced Purchase Intent', value: 0 },
      ],
      hasMore: false,
      children: [],
    });
    // console.log('data', data);
    let hasMoreFlag = false;
    let brandRecognition = 0;
    let brandAppeal = 0;
    let brandUpi = 0;
    let market = [];
    const brandObj = {
      id: 0,
      title: brandValue,
      metrics: [],
      hasMore: hasMoreFlag,
      children: market,
    };

    // 分组国家
    const marketGroup = data.reduce((acc, item) => {
      if (item.market) {
        acc[item.market] = acc[item.market] || [];
        acc[item.market].push(item);
      }
      return acc;
    }, {});
    // 有国家遍历国家
    if (Object.keys(marketGroup).length > 0) {
      hasMoreFlag = true;
      for (const key of Object.keys(marketGroup)) {
        const marketOne = marketGroup[key];
        let marketHasMoreFlag = false;
        let marketBrandRecognition = 0;
        let marketBrandAppeal = 0;
        let marketBrandUpi = 0;
        let product = [];
        let marketObj = {
          id: 1,
          flag: key.toLocaleLowerCase(),
          title: key,
          metrics: [],
          hasMore: marketHasMoreFlag,
          children: product,
        };
        // 分组产品
        if (marketOne.length > 0) {
          marketHasMoreFlag = true;
          for (const item of marketOne) {
            marketBrandRecognition += item.brand_logo_visibility;
            marketBrandAppeal += item.appeal;
            marketBrandUpi += item.upi;
            let marketProduct = {
              id: 2,
              title: item.display_name,
              metrics: [
                {
                  label: 'Brand Visibility',
                  value: item.brand_logo_visibility,
                  flag: markFlags(item.brand_logo_visibility),
                },
                {
                  label: 'Appeal',
                  value: item.appeal,
                  flag: markFlags(item.appeal),
                },
                {
                  label: 'Unpriced Purchase Intent',
                  value: item.upi,
                  flag: markFlags(item.upi),
                },
              ],
              img: item.img,
            };
            // 添加产品到marketObj 只添加2个
            if (marketObj.children.length < 2) {
              product.push(marketProduct);
            }
          }
          // 计算分数
          marketObj.metrics = [
            {
              label: 'Brand Visibility',
              value: parseFloat(
                marketBrandRecognition / marketOne.length
              ).toFixed(2),
              flag: markFlags(
                parseFloat(marketBrandRecognition / marketOne.length).toFixed(2)
              ),
            },
            {
              label: 'Appeal',
              value: parseFloat(marketBrandAppeal / marketOne.length).toFixed(
                2
              ),
              flag: markFlags(
                parseFloat(marketBrandAppeal / marketOne.length).toFixed(2)
              ),
            },
            {
              label: 'Unpriced Purchase Intent',
              value: parseFloat(marketBrandUpi / marketOne.length).toFixed(2),
              flag: markFlags(
                parseFloat(marketBrandUpi / marketOne.length).toFixed(2)
              ),
            },
          ];
        }
        // 计算分数
        brandRecognition += marketBrandRecognition / marketOne.length;
        brandAppeal += marketBrandAppeal / marketOne.length;
        brandUpi += marketBrandUpi / marketOne.length;
        // 添加国家到brandObj
        market.push(marketObj);
      }
      // 计算分数
      brandObj.metrics = [
        {
          label: 'Brand Visibility',
          value: parseFloat(
            brandRecognition / Object.keys(marketGroup).length
          ).toFixed(2),
          flag: markFlags(
            parseFloat(
              brandRecognition / Object.keys(marketGroup).length
            ).toFixed(2)
          ),
        },
        {
          label: 'Appeal',
          value: parseFloat(
            brandAppeal / Object.keys(marketGroup).length
          ).toFixed(2),
          flag: markFlags(
            parseFloat(brandAppeal / Object.keys(marketGroup).length).toFixed(2)
          ),
        },
        {
          label: 'Unpriced Purchase Intent',
          value: parseFloat(brandUpi / Object.keys(marketGroup).length).toFixed(
            2
          ),
          flag: markFlags(
            parseFloat(brandUpi / Object.keys(marketGroup).length).toFixed(2)
          ),
        },
      ];
    }
    // console.log('brandObj', brandObj);
    setBrandData(brandObj);
  };

  useEffect(() => {
    // console.log('brandValueChange', brandValue);
    const fetchDataAndBuild = async () => {
      if (brandValue && brandValue !== '') {
        // console.log('fetchDataAndBuild');
        await fetchData();
      }
    };
    fetchDataAndBuild();
  }, [brandValue]);

  // filter事件
  const handleFilter = (newValue) => {
    setBrandValue(newValue);
  };
  const branchCountryRef = useRef(null);
  const scrollTimeout = useRef(null);
  const [showBranch2, setShowBranch2] = useState(false);
  const [showList, setShowList] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  });
  const [zoom, setZoom] = useState(0);
  const refs = useRef([]);
  const pendingScrollIndex = useRef(null);

  const handleShowBranch2 = () => {
    setShowBranch2(!showBranch2);
  };

  const handleShowBranch3 = (index) => {
    setShowList((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
    pendingScrollIndex.current = index;
  };

  const handleZoomIn = () => {
    setZoom(0);
  };

  const handleZoomOut = () => {
    setZoom(1);
  };

  const zoomClass = useMemo(() => {
    switch (zoom) {
      case 0:
        return style.zoomN;
      case 1:
        return style.zoomS;
      case 2:
        return style.zoomM;
      case 3:
        return style.zoomL;
      default:
        return style.zoomN;
    }
  }, [zoom]);

  useEffect(() => {
    if (showBranch2) {
      scheduleScroll();
    } else {
      setShowList({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      });
    }
  }, [showBranch2]);

  useEffect(() => {
    if (pendingScrollIndex.current !== null) {
      scrollToTarget(pendingScrollIndex.current);
      pendingScrollIndex.current = null;
    }
  }, [showList]);

  const scheduleScroll = () => {
    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }
    scrollTimeout.current = setTimeout(() => {
      branchCountryRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  };

  const scrollToTarget = (index) => {
    if (refs.current[index]) {
      refs.current[index].scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00a32e',
          colorError: '#eb0000',
          colorSuccess: '#06ca3d',
          fontFamily: 'unilever-shilling-regular',
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className={style.box1}>
        <TopBar />
        <BorderBox className={style.box11}>
          <div className={style.box111}>
            <div className={style.zoomIn} onClick={() => handleZoomIn()}></div>
            <div
              className={style.zoomOut}
              onClick={() => handleZoomOut()}
            ></div>
            <div className={classNames(style.brandWrap)}>
              <div className={classNames(style.zoomWrap, zoomClass)}>
                <div className={style.firstBranch}>
                  <div className={style.brandItem}>
                    <div className={style.brandHeader}>
                      <div className={style.text}>
                        <span className={style.brandIcon}>
                          <img src="" alt="" />
                        </span>
                        <span className={style.transInput}>
                          <Select
                            placeholder="select brand"
                            allowClear={false}
                            onChange={(newValue) => {
                              handleFilter(newValue, 3);
                            }}
                            value={brandValue}
                            options={OptionsBrand}
                          />
                        </span>
                        <s className={style.arr}></s>
                      </div>
                    </div>
                    <div className={style.brandContent}>
                      <div className={style.flexWrap}>
                        {brandData.metrics.map((metric, idx) => (
                          <div className={style.brandCon} key={idx}>
                            <div className={style.txt}>{metric.label}</div>
                            <div
                              className={classNames(
                                style.circle,
                                { [style.red]: metric.flag === 'worse' },
                                { [style.yellow]: metric.flag === 'bad' }
                              )}
                            >
                              {metric.value}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      className={classNames(style.more, {
                        [style.active]: showBranch2,
                      })}
                      onClick={handleShowBranch2}
                    >
                      <div className={style.moreBtn}>
                        <s className={style.iconPlus}></s>
                      </div>
                      <div
                        className={classNames(style.line, {
                          [style.noData]: brandData.children.length < 3,
                        })}
                      ></div>
                      <div
                        className={classNames(style.verticalLine, {
                          [style.noData]: brandData.children.length < 2,
                        })}
                      >
                        <div className={style.lineLeft}></div>
                        <div className={style.lineRight}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  ref={branchCountryRef}
                  className={classNames(style.secondBranch, {
                    [style.active]: showBranch2,
                  })}
                >
                  {brandData.children.map((item, idx) => {
                    let slideName = '';
                    if (idx === 0 || idx === 3) slideName = style.slideL;
                    if (idx === 1 || idx === 4) slideName = style.slideC;
                    if (idx === 2 || idx === 5) slideName = style.slideR;

                    return (
                      <div
                        className={classNames(style.brandItem, {
                          [style.slide]: showList[idx],
                        })}
                        key={idx}
                      >
                        <div className={style.brandHeader}>
                          <div className={classNames(style.text, style.second)}>
                            <i
                              className={classNames(
                                style.iconFlag,
                                style[item.flag]
                              )}
                            ></i>
                            {item.title}
                          </div>
                        </div>
                        <div className={style.brandContent}>
                          <div className={style.flexWrap}>
                            {item.metrics.map((metric, i) => (
                              <div className={style.brandCon} key={i}>
                                <div className={style.txt}>{metric.label}</div>
                                <div
                                  className={classNames(
                                    style.circle,
                                    { [style.red]: metric.flag === 'worse' },
                                    { [style.yellow]: metric.flag === 'bad' }
                                  )}
                                >
                                  {metric.value}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div
                          className={classNames(style.more, {
                            [slideName]: showList[idx],
                            [style.save]: !showList[idx],
                          })}
                          onClick={() => handleShowBranch3(idx)}
                        >
                          <div className={style.moreBtn}>
                            <s className={style.iconPlus}></s>
                          </div>
                        </div>
                        <div
                          className={classNames(style.lineWrap, {
                            [slideName]: showList[idx],
                          })}
                        >
                          <div
                            className={classNames(style.vLine, {
                              [style.noData]: item.children.length < 2,
                            })}
                          >
                            <div className={style.tLine}></div>
                          </div>
                        </div>
                        <div
                          ref={(el) => (refs.current[idx] = el)}
                          className={classNames(style.thirdCon, {
                            [slideName]: showList[idx],
                          })}
                        >
                          {item.children.map((child, id) => (
                            <div
                              className={classNames(style.brandItem)}
                              key={id}
                            >
                              <div
                                className={classNames(
                                  style.brandHeader,
                                  style.third
                                )}
                              >
                                <p className={style.text}>{child.title}</p>
                              </div>
                              <div
                                className={classNames(
                                  style.brandContent,
                                  style.third
                                )}
                              >
                                <div className={classNames(style.imgCon)}>
                                  <img src={child.img} alt="" />
                                </div>
                                <div
                                  className={classNames(
                                    style.flexWrap,
                                    style.third
                                  )}
                                >
                                  {child.metrics.map((metric, i) => (
                                    <div
                                      className={style.brandConShort}
                                      key={i}
                                    >
                                      <div className={style.txt}>
                                        {metric.label}
                                      </div>
                                      <div
                                        className={classNames(
                                          style.circle,
                                          {
                                            [style.red]:
                                              metric.flag === 'worse',
                                          },
                                          {
                                            [style.yellow]:
                                              metric.flag === 'bad',
                                          }
                                        )}
                                      >
                                        {metric.value}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                  <div className={style.whitespace}></div>
                </div>
              </div>
            </div>
          </div>
        </BorderBox>
        <VideoBg />
      </div>
    </ConfigProvider>
  );
}

export default BrandPage;
