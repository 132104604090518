import React, { createContext, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [avgs, setAvgs] = useState([]);

  const showModal = (obj, avgs) => {
    setIsOpen(true);
    setModalData(obj);
    setAvgs(avgs);
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalData({});
    setAvgs([]);
  };

  return (
    <ModalContext.Provider
      value={{ isOpen, modalData, showModal, closeModal, avgs }}
    >
      {children}
    </ModalContext.Provider>
  );
};
