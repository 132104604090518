import { Col, Row } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import Loading from '../../components/loading.jsx';
import { checkAoi } from './checkAoi.js';
import style from './design.module.scss';
import Dialog from './dialog.jsx';

function Aoi({ url, onClick, complete = false }) {
  const [loading, setLoading] = useState(true);
  function onLoad() {
    setLoading(false);
  }
  function handleClick() {
    if (loading) return;
    onClick && onClick();
  }

  return (
    <div className={style.aoiBox}>
      <div className={style.aoiBoxInner} onClick={handleClick}>
        <img className={style.aoiImg} src={url} onLoad={onLoad} />
      </div>
      {complete ? (
        <div className={style.completeTag}>Edited</div>
      ) : (
        <div className={style.incompleteTag}>Unedited</div>
      )}
      {loading && <Loading />}
    </div>
  );
}

function PackageAiDesign({
  className = '',
  numberOfVariantsPerDesign = 1,
  assetDesign = {},
  designName = 'Unilever Brand Current',
  design = { variants: [] },
  setDesign,
  onHelpClick,
}) {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [selectedVariant, setSelectedVariant] = useState(0);
  const complete1 = useMemo(() => {
    if (!design?.variants[0]?.aois) return false;
    return (
      checkAoi(design.variants[0].aois[0]) &&
      checkAoi(design.variants[0].aois[0])
    );
  }, [design]);
  const complete2 = useMemo(() => {
    if (!design?.variants[1]?.aois) return false;
    return (
      checkAoi(design.variants[1].aois[0]) &&
      checkAoi(design.variants[1].aois[0])
    );
  }, [design]);

  const assetVariant = useMemo(() => {
    return assetDesign.variants[selectedVariant];
  }, [assetDesign, selectedVariant]);

  const variant = useMemo(() => {
    return design.variants[selectedVariant];
  }, [design, selectedVariant]);

  const setVariant = useCallback(
    (v) => {
      // set data
      const nd = { ...design };
      nd.variants[selectedVariant] = v;
      if (selectedVariant === 0 && numberOfVariantsPerDesign > 1) {
        nd.variants[1] = v;
      }
      setDesign(nd);
    },
    [selectedVariant, numberOfVariantsPerDesign, design, setDesign]
  );

  function onImgClick(index) {
    setSelectedVariant(index);
    setDialogVisible(true);
  }

  return (
    <div className={`${style.designBox} ${className}`}>
      <h3
        className={`${style.designTitle} ${
          numberOfVariantsPerDesign > 1 ? '' : style.designTitle2
        }`}
      >
        {design.name}
      </h3>
      <Row className={style.designBody} gutter={12}>
        <Col span={12}>
          <Aoi
            url={assetDesign.variants[0].url}
            complete={complete1}
            onClick={() => onImgClick(0)}
          />
        </Col>
        {numberOfVariantsPerDesign > 1 && (
          <Col span={12}>
            <Aoi
              url={assetDesign.variants[1].url}
              complete={complete2}
              onClick={() => onImgClick(1)}
            />
          </Col>
        )}
      </Row>
      <Dialog
        visible={dialogVisible}
        setVisible={setDialogVisible}
        designName={designName}
        assetVariant={assetVariant}
        variant={variant}
        setVariant={setVariant}
        onHelpClick={onHelpClick}
      />
    </div>
  );
}

export default PackageAiDesign;
