import {
  ArrowLeftOutlined,
  FilterOutlined,
  HeartFilled,
  HeartOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Button, message, Select } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { putFileKey } from '../../apis/file';
import {
  getPredictPackage,
  getPredictPackageFilter,
  getPredictPackageList,
  putPredictPackageLike,
} from '../../apis/serve';
import CategoryJson from '../../assets/json/category.json';
import Box from '../../components/box.jsx';
import Container from '../../components/container.jsx';
import style from './index.module.scss';
import ResizeBox from './resize.jsx';

function Title() {
  const navigate = useNavigate();
  function onBack() {
    navigate(-1);
  }

  return (
    <div className={style.title}>
      <ArrowLeftOutlined onClick={onBack} high={14} width={14} />
      <h1>Database</h1>
    </div>
  );
}

function Filter({
  filter = {},
  setFilter,
  className = '',
  options = { brand: [], category: [] },
}) {
  const [brand, setBrand] = useState(filter.brand);
  const [category, setCategory] = useState(filter.category);
  const [isFavorite, setIsFavorite] = useState(filter.isFavorite);

  function onFavorite() {
    setIsFavorite((f) => !f);
  }

  useEffect(() => {
    setFilter((f) => ({ ...f, brand, category, favorite: isFavorite }));
  }, [brand, category, isFavorite]);

  const categoryOptions = useMemo(() => {
    return CategoryJson.map((e) => ({ label: e, value: e }));
  }, []);

  return (
    <div className={`${style.filterBox} ${className}`}>
      <div className={style.filterLeft}>
        <Select
          className={style.filterSelect}
          options={options.brand}
          value={brand}
          onChange={setBrand}
          placeholder={
            <>
              <FilterOutlined /> Brand
            </>
          }
          mode="multiple"
          allowClear={true}
        ></Select>
        <Select
          className={style.filterSelect}
          options={categoryOptions}
          value={category}
          onChange={setCategory}
          placeholder={
            <>
              <FilterOutlined /> Category
            </>
          }
          mode="multiple"
          allowClear={true}
        ></Select>
      </div>
      <Button
        icon={isFavorite ? <HeartFilled /> : <HeartOutlined />}
        onClick={onFavorite}
      >
        My favorite
      </Button>
    </div>
  );
}

function ListBox({ children }) {
  return <div className={style.listBox}>{children}</div>;
}

function ListBoxInner({ children }) {
  return (
    <div className={style.listBoxInner}>
      <div className={style.list}>{children}</div>
    </div>
  );
}

function ListItem({ item }) {
  const navigate = useNavigate();
  // favorite
  const [favorite, setFavorite] = useState(item.is_favorited);
  async function onFavorite(ev) {
    try {
      ev.stopPropagation();
      await putPredictPackageLike(item.id, !item.is_favorited);
      // const { data } = await getPredictPackage(item.id);
      // if (!data) return;
      setFavorite((f) => !f);
    } catch (err) {
      console.error('onFav error', err);
      message.error('Favorite error');
    }
  }

  // image url
  const [url, setUrl] = useState('');
  async function getUrl() {
    const { data } = await putFileKey(
      item.ul_design0_variant0 ?? item.ul_design1_variant0
    );
    if (data) {
      setUrl(data.url);
    }
  }
  useEffect(() => {
    void getUrl();
  }, [item]);

  // goto result
  async function onClick() {
    const { data } = await getPredictPackage(item.id);
    if (!data) return;
    const ctx = { res: { predictPackage: data } };
    sessionStorage.setItem('packageAiContext', JSON.stringify(ctx));
    navigate('/package-ai-screening-wait');
  }

  function onShare(ev) {
    ev.stopPropagation();
    const url = new URL(window.location.href);
    url.pathname = '/package-ai-screening-share';
    url.searchParams.set('id', item.id);
    navigator.clipboard.writeText(url.toString());
    message.success('Copied to clipboard');
  }

  return (
    <div className={style.listItem} onClick={onClick}>
      <div className={style.listItemImgBox}>
        <img src={url} alt={item.project_name} loading="lazy" />
      </div>
      <div className={style.listItemInfoBox}>
        <h3 title={item.project_name}>{item.project_name}</h3>
        <div className={style.listItemFavBox} onClick={onShare}>
          <ShareAltOutlined size={16} />
        </div>
        <div className={style.listItemFavBox} onClick={onFavorite}>
          {favorite ? <HeartFilled size={16} /> : <HeartOutlined size={16} />}
        </div>
      </div>
    </div>
  );
}

function PackageAiView() {
  // my tests
  const [filter1, setFilter1] = useState({});
  const [list1, setList1] = useState([]);
  const [loading1, setLoading1] = useState(false);
  const [page1, setPage1] = useState(1);
  const [totalPages1, setTotalPages1] = useState(1);
  async function getList1() {
    if (loading1) return;
    setLoading1(true);
    try {
      const { pages, data } = await getPredictPackageList(filter1, page1, 20);
      setTotalPages1(pages);
      if (page1 === 1) {
        setList1(data);
      } else {
        setList1((l) => {
          return l.concat(data);
        });
      }
    } catch (err) {
      console.error('get list 1 error', err);
      message.error('Get data error');
    }
    setLoading1(false);
  }
  function onNext1() {
    if (page1 < totalPages1) {
      setPage1((p) => p + 1);
    }
  }
  useEffect(() => {
    if (page1 === 1) {
      void getList1();
    } else {
      setPage1(1);
    }
  }, [filter1]);
  useEffect(() => {
    void getList1();
  }, [page1]);
  const bottomRef1 = useRef(null);
  const [scrollCount1, setScrollCount1] = useState(0);
  useEffect(() => {
    if (!bottomRef1.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setScrollCount1((c) => c + 1);
      }
    });
    observer.observe(bottomRef1.current);
    return () => {
      observer.disconnect();
    };
  }, [bottomRef1.current]);
  useEffect(() => {
    if (scrollCount1 > 0) {
      onNext1();
    }
  }, [scrollCount1]);

  // public
  const [filter2, setFilter2] = useState({ share: true });
  const [list2, setList2] = useState([]);
  const [loading2, setLoading2] = useState(false);
  const [page2, setPage2] = useState(1);
  const [totalPages2, setTotalPages2] = useState(1);
  async function getList2() {
    if (loading2) return;
    setLoading2(true);
    try {
      const { pages, data } = await getPredictPackageList(filter2, page2, 20);
      setTotalPages2(pages);
      if (page2 === 1) {
        setList2(data);
      } else {
        setList2((l) => {
          return l.concat(data);
        });
      }
    } catch (err) {
      console.error('get list 2 error', err);
      message.error('Get data error');
    }
    setLoading2(false);
  }
  function onNext2() {
    if (page2 < totalPages2) {
      setPage2((p) => p + 1);
    }
  }
  useEffect(() => {
    if (page2 === 1) {
      void getList2();
    } else {
      setPage2(1);
    }
  }, [filter2]);
  useEffect(() => {
    void getList2();
  }, [page2]);
  const bottomRef2 = useRef(null);
  const [scrollCount, setScrollCount] = useState(0);
  useEffect(() => {
    if (!bottomRef2.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setScrollCount((c) => c + 1);
      }
    });
    observer.observe(bottomRef2.current);
    return () => {
      observer.disconnect();
    };
  }, [bottomRef2.current]);
  useEffect(() => {
    if (scrollCount > 0) {
      onNext2();
    }
  }, [scrollCount]);

  const [options, setOptions] = useState({ brand: [], category: [] });
  async function initOptions() {
    const { data } = await getPredictPackageFilter();
    if (!data) return;
    const { category, brand } = data;
    setOptions({
      category: category.map((e) => ({ label: e, value: e })),
      brand: brand.map((e) => ({ label: e, value: e })),
    });
  }
  useEffect(() => {
    void initOptions();
  }, []);

  return (
    <Container noDatabase={true}>
      <div className={style.containerInner}>
        <Box title={<Title />} className={style.box}>
          <ResizeBox
            className={style.boxInner}
            item1={
              <Box title="My Tests" className={style.itemBox}>
                <ListBox>
                  <Filter
                    filter={filter1}
                    setFilter={setFilter1}
                    options={options}
                  />
                  <ListBoxInner>
                    {list1.map((e) => {
                      return <ListItem item={e} key={e.id} />;
                    })}
                    <div ref={bottomRef1} />
                  </ListBoxInner>
                </ListBox>
              </Box>
            }
            item2={
              <Box title="Public" className={style.itemBox}>
                <ListBox>
                  <Filter
                    filter={filter2}
                    setFilter={setFilter2}
                    options={options}
                  />
                  <ListBoxInner>
                    {list2.map((e) => {
                      return <ListItem item={e} key={e.id} />;
                    })}
                    <div ref={bottomRef2} />
                  </ListBoxInner>
                </ListBox>
              </Box>
            }
          />
        </Box>
      </div>
    </Container>
  );
}

export default PackageAiView;
