import React from 'react';
import style from './login-page.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../store/loginSlice';
import { Button, Form, Input, App } from 'antd';
import { getUser, login } from '@/api/request.js';


let message;

const LoginPage = () => {
  const staticFunction = App.useApp();
  message = staticFunction.message;
  const login_data = useSelector((state)=> state.login.data);
  const dispatch = useDispatch();

  const onFinish = (values) => {

    let data = { username: values.username, password: values.password };
    console.log('login data:', login_data);
    // TODO：可以写Async Await形式
    login(data)
      .then((res) => {
        message.success('login success');
        sessionStorage.setItem('jwt-access-token', res.access_token);

        return getUser();
      })
      .then((res) => {
        dispatch(setUserData({ user: res.data }));
        return res;
      })
      .then(() => window.open('/navbar', '_self'))
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <Form
      className={style.loginForm}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      style={{
        maxWidth: 600,
      }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginPage;
export { message };
